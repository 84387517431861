<template>
    <div class="home">
        <div class="date_box">
            <div class="one">
                <img  class="photo" v-lazy="data.photo" alt="">
               
                <div>
                    <div class="name">{{ data.dispname }}</div>
                    <div class="fee_box">费用<span class="fee">￥{{ data.fee }}</span></div>
                </div>
            </div>


            <div class="bg_box">

                <div class="info_item">
                    <div>预约类型：</div><span>{{ data.apptypename }}</span>
                </div>
                <div class="info_item">
                    <div>就诊诊所：</div><span>{{ data.clinicname }}</span>
                </div>
                <div class="info_item">
                    <div>预约时间：</div><span>{{ data.appointmenttime }}</span>
                </div>
                <div class="info_item" @click="getWxConfig()">
                    <div>就诊地点：</div><span>{{ data.address }}<br>电话：{{ data.phone }}</span><i
                        class="icon intro iconfont iconsanjiaoyou"></i>
                </div>
                <div class="info_item" v-if="data.warningimg||(data.warningcontent&& !(/^ *$/.test(data.warningcontent)))" @click="show_info = true">
                    <div>交通指引：</div><span class="info_l">点击查看医馆交通指引</span><i
                        class="icon  info_l iconfont iconsanjiaoyou"></i>
                </div>
            </div>


            <div class="cell_box">
                <van-cell :border="false" is-link title="预约患者：" :value="customername" @click="show = true;setempty=true" />
            </div>
            <div class="flex">
                 <van-button  round block @click="sub()" :loading="loading_bt">确定预约</van-button>
            </div>
           
        </div>

        <h3>预约须知：</h3>
        <div class="intro" style=" line-height: 25px;font-family: PingFangSC-Regular;">
            1.点击“确定预约”之后请在30分钟之内支付订单费用，否则订单将自动取消。<br>
            2.每一个账号内“取消订单”最多不超过三次。<br>
            3.同一用户当天不能多次预约同一医生。 <br>
        </div>
        <van-dialog v-model="show" @confirm="confirm">
            <van-sticky :offset-top="0">
                <div class="title">
                     <div>选择患者</div>
                  
                </div>
                <van-icon class="close_icon" name="cross"  @click="()=>{show=false;setempty = false}"  />
                </van-sticky>
                <div>
                     <patientList v-if="setempty" @patientData="patientFromChild" :offset="41" :planid="planid"  :qrytype="data.apptype" :p_clinicid="clinicid" :p_checked="checked"/>
                </div>
        </van-dialog>
        <van-dialog v-model="show_info" confirm-button-text="关闭" title="就诊交通指引">
            <div class="dialog_box">
                <img v-if=" data.warningimg" :src="data.warningimg" />
                <h3 v-if="data.warningcontent&& !(/^ *$/.test(data.warningcontent))" style="font-size: 18px;">地址指引：</h3>
                <div v-if=" data.warningcontent" class="intro" style=" line-height: 25px;font-size: 16px;">{{ data.warningcontent }}</div>
            </div>

        </van-dialog>

    </div>
</template>
<script>
import { wxappointmentplandetail, wxjsapiauth, wxconfirmappointment } from '@/api/medical'
import { mapGetters } from 'vuex'
import patientList from '../patientList/index.vue'
import wx from 'weixin-js-sdk'
import { Toast,Lazyload } from 'vant';
import Vue from 'vue';
import errorImg from '@/assets/医生头像.png'
Vue.use(Lazyload, {
        lazyComponent: true,
        loading: "https://yz.iyzzy.com/icon/loading.gif",
        error: errorImg
    });
export default {
    components: { patientList },
    computed: {
        ...mapGetters([
            'username', 'clinicid', 'openid'
        ])
    },
    data() {
        return {
            data: {},
            planid: 0,
            show: false,
            customername: '',
            customerid: '',
            userid: '',
            show_info: false,
            loading_bt: false,
            checked:0,
            p_customername: '',
            p_customerid: '',
            p_userid: '',
            p_checked:0,
            setempty:true
        }
    },
    created() {
        this.$store.dispatch('hideOrShowNav', false)
        this.planid = this.$route.query.planid || ''
        this.getPlan()
    },

    methods: {
        getPlan() {
            let data = { username: this.username, planid: this.planid }
            wxappointmentplandetail(data).then(response =>{
            this.data = response.response_body
            })
        },
        getWxConfig() {
            //注入权限验证
            let data = { url: window.location.href, openid: this.openid };
            wxjsapiauth(data).then(response => {

                let jsApiList = [
                    'openLocation'
                ]
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: response.response_body.appid, // 必填，公众号的唯一标识
                    timestamp: response.response_body.timestamp, // 必填，生成签名的时间戳
                    nonceStr: response.response_body.noncestr, // 必填，生成签名的随机串
                    signature: response.response_body.signature, // 必填，签名
                    url: response.response_body.url,
                    jsApiList: jsApiList // 必填，需要使用的JS接口列表
                });
                let latitude = this.data.latitude
                let longitude = this.data.longitude
                let clinicname = this.clinicname
                let address = this.data.address
                wx.showOptionMenu();
                wx.ready(function () {
                    console.log('监听，打开微信地图')
                    //监听，打开微信地图
                    wx.openLocation({
                        latitude: parseFloat(latitude || 0), // 纬度，浮点数，范围为90 ~ -90
                        longitude: parseFloat(longitude || 0), // 经度，浮点数，范围为180 ~ -180。
                        name: clinicname, // 位置名
                        scale: 15,// 地图缩放级别,整形值,范围从1~28。默认为最大
                        address: address, // 地址详情说明
                        //infoUrl: 'https://www.baidu.com',
                        // success(res) {
                        //     console.log("成功返回：", res)
                        // }, fail(eer) {
                        //     console.log("失败返回：", eer)
                        // }
                    });

                });
            })
        },
        patientFromChild(data) {
            this.p_customerid = data.customerid
            this.p_customername = data.customername
            this.p_userid = data.userid
            this.p_checked = data.checked
        },
        confirm(){
            this.setempty = true
            this.customerid = this.p_customerid;
            this.customername = this.p_customername;
            this.userid = this.p_userid;
            this.checked = this.p_checked
        },
        sub() {
           
            if(this.userid==''){
                Toast.fail('请先选择预约患者');
                return
            } 
            let sub_data = {
                username: this.username,
                planid: this.planid,
                userid: this.userid,
                customerid: this.customerid
            }
            this.$dialog.confirm({
                title: null,
                message: "是否确认预约",
            }).then(() => {
                this.loading_bt = true
                wxconfirmappointment(sub_data).then(response => {
                    this.loading_bt = false
                if(response.response_body.ispay=='1'){
                    Toast.success('预约成功');
                }else{
                    Toast.success('预约成功，请告知客户在规定时间内付款');
                }  
            }).then(() =>{
                setTimeout(()=>{
                    this.$router.go(-1)
                    this.$store.dispatch('changeIsRefresh', '2')
                },2000)
             
            }).catch(() => {
                    this.loading_bt = false
                    Toast.success('预约失败');
                });
            })
        }

    }
}

</script>
<style lang="scss" scoped>
.home {
    padding: 15px;
    display: block;
    background: rgb(246, 246, 244);
    height: 100vh;
    overflow:auto;

    .one {
        display: flex;
    }

    .name {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }

    .tab_box {
        display: flex;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
        width: 100%;
        margin-top: 10px;
    }

    .tab_item {
        flex: 1;
        font-size: 18px;
        font-weight: 600;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #c2c0b9;
        color: #fff;
    }

    .fee {
        width: 100px;
        text-align: end;
        color: rgb(207, 140, 87);
    }

    .photo {
        width: 2rem;
        height: 2.5rem;
        border-radius: 10px;
        margin-right: 10px;
    }

    .bg_box {
        background-color: #fff;
        border-radius: 10px;
        padding: 5px 15px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .iconsanjiaoyou {
        font-size: 26px;
        width: 15px;
    }

    .intro {
        color: #999995 !important;
        white-space: pre-wrap;
        line-height: 0.55rem;
        word-break:break-all
    }

    .info_item {
        display: flex;
        color: #333;
        line-height: 24px;
        padding: 5px 0px;

        div {
            min-width: 80px;
        }

        span {
            color: #999995;
        }
    }

    .iconsanjiaoyou {
        font-size: 26px;
        width: 15px;
    }

    .info_l {
        color: rgb(207, 140, 87) !important;
    }

    h3 {
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .cell_box {
        margin-bottom: 15px;
    }

    .dialog_box {
        padding: 10px 15px;

        img {
            width: 100%;
        }
    }


    :deep .van-dialog__content {
        max-height: 450px;
        overflow: auto;
    }

    :deep .van-dialog {
        width: 90%;
    }

    :deep .left {
        width: unset !important;
        display: flex;
    }

    :deep .right {
        width: unset !important;
    }

    :deep .treat_main_list {
        width: 100%;

        .van-radio {
            overflow: visible;

        }
    }

    :deep .van-radio {
        display: flex;
    }

    :deep .btnimg {
        display: none;
    }

    :deep .detail_info {
        display: none !important;
    }

    :deep .van-dialog__header {
        padding: 10px 0px !important;
    }

    :deep .van-cell {
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :deep .van-icon {
        height: unset !important;
    }

    :deep .van-radio__icon {
        height: 1.1rem;
    }
    :deep .van-button--round{
        background-color: #CF8C57;
        width: 150px;
        height: 38px;
        text-align: center;
        line-height: 38px;
        color: #fff;border-radius: 21px;
    }
    .flex{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .fee_box{
        margin-top: 1rem;
    }
    :deep .text-overflow{
        max-width: 3rem;
        display: block !important;

    }
    :deep .treat_main_list{
        margin-bottom: 0;
    }
    :deep .keyword_input{
        .van-sticky--fixed{
            top: 41px;
            z-index: 1;
            margin: 0px 4px;
        }
    }
    .title{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 10px 10px;
        
    }
    .close_icon{
            color: #969799;
            position: absolute !important;
            top: 11px;
            right: 13px;
            font-size: 20px;
        }
     
}</style>